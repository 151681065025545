<template>
  <div class="all-house-rules">
    <AdminTable title="All House Rules" :list="houseRuleList" :fields="fields" :loading="loading"
    @update-list="update" />
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'

import { houseRuleService } from '@/services/admin'

export default {
  name: 'AdminHouseRuleList',
  components: {
    AdminTable,
  },
  data() {
    return {
      houseRuleList: [],
      loading: false,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID'},
        { key: 'name', label: 'Name'},
        { key: 'defaultValue', label: 'Default Value' },
        { key: 'translations.en', label: 'English', flag: 'us' },
        { key: 'translations.it', label: 'Italiano', flag: 'it' },
        { key: 'translations.es', label: 'Español', flag: 'es' },
        // { key: 'delete', label: 'Delete' },
      ]
    }
  },
  async beforeMount() {
    await this.getHouseRuleList()
  },
  methods: {
    async getHouseRuleList() {
      this.loading = true
      this.houseRuleList = await houseRuleService.getAllRules()
      this.$store.commit('adminHouseRuleStore/setHouseRules', this.houseRuleList)
      this.loading = false
    },
    async deleteHouseRule(houseRule) {
      await houseRuleService.deleteHouseRule(houseRule)
      await this.getHouseRuleList()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
